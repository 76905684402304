<template>
  <a-card :bordered="false">
    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">
      <span slot="user_type" slot-scope="text">
        {{ text | filterUserType }}
      </span>
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>

  </a-card>
</template>

<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '用户类型',
    dataIndex: 'user_type',
    scopedSlots: { customRender: 'user_type' }
  },
  {
    title: '账户ID',
    dataIndex: 'auth_user_id'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  }
]

export default {
  name: 'RealnameList',
  data() {
    return {
      loading: true,
      columns,
      tableData: []
    }
  },
  created() {
    this.loadData()
  },
  filters: {
    filterUserType(val) {
      if (val === 'user') {
        return '用户'
      } else {
        return '教员'
      }
    }
  },
  methods: {
    loadData() {
      this.loading = true
      this.$http({
        url: 'admin/realname/log',
        method: 'get'
      }).then(res => {
        this.loading = false
        if (res.status === 'success') {
          this.tableData = res.data.list
        } else {
          this.notifacation.error({
            message: res.message,
            description: ''
          })
        }
      })
    }
  }
}
</script>
